import {
    FETCH_USERS_REQUEST, FETCH_USERS_ERROR, FETCH_USERS_SUCCESS,
    UPDATE_USER_REQUEST, UPDATE_USER_ERROR, UPDATE_USER_SUCCESS,
    ADD_USER_REQUEST, ADD_USER_ERROR, ADD_USER_SUCCESS,
    DELETE_USER_REQUEST, DELETE_USER_ERROR, DELETE_USER_SUCCESS,
} from '../actions/types'

const defaultState = {
    users: [],
    FETCH_USERS_REQUEST: false,
    FETCH_USERS_ERROR: null,
    FETCH_USERS_SUCCESS: false,
    UPDATE_USER_REQUEST: null,
    UPDATE_USER_ERROR: null,
    UPDATE_USER_SUCCESS: null,
    ADD_USER_REQUEST: null,
    ADD_USER_ERROR: null,
    ADD_USER_SUCCESS: null,
    DELETE_USER_REQUEST: null,
    DELETE_USER_ERROR: null,
    DELETE_USER_SUCCESS: null
};

export default function mobileReducer(state = defaultState, action) {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
            return {
                ...state,
                FETCH_USERS_REQUEST: true,
                FETCH_USERS_ERROR: null,
                FETCH_USERS_SUCCESS: null
            };
        case FETCH_USERS_ERROR:
            return {
                ...state,
                FETCH_USERS_REQUEST: null,
                FETCH_USERS_ERROR: action.payload
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                FETCH_USERS_REQUEST: null,
                FETCH_USERS_SUCCESS: true,
                users: action.payload
            };
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                UPDATE_USER_REQUEST: true,
                UPDATE_USER_ERROR: null,
                UPDATE_USER_SUCCESS: null
            };
        case UPDATE_USER_ERROR:
            return {
                ...state,
                UPDATE_USER_REQUEST: null,
                UPDATE_USER_ERROR: action.payload
            };

        case UPDATE_USER_SUCCESS:
            const updatedUsersList = state.users.map(user => {
                if (user.username !== action.payload.username) return user
                return {
                    ...action.payload,
                }
            })
            return {
                ...state,
                UPDATE_USER_REQUEST: null,
                UPDATE_USER_SUCCESS: true,
                users: updatedUsersList
            };
        case ADD_USER_REQUEST:
            return {
                ...state,
                ADD_USER_REQUEST: true,
                ADD_USER_ERROR: null,
                ADD_USER_SUCCESS: null
            }
        case ADD_USER_ERROR:
            return {
                ...state,
                ADD_USER_REQUEST: null,
                ADD_USER_ERROR: action.payload
            }
        case ADD_USER_SUCCESS:
            return {
                ...state,
                ADD_USER_REQUEST: null,
                ADD_USER_SUCCESS: true,
                users: [...state.users, action.payload]
            }
        case DELETE_USER_REQUEST:
            return {
                ...state,
                DELETE_USER_REQUEST: true,
                DELETE_USER_ERROR: null,
                DELETE_USER_SUCCESS: null
            }
        case DELETE_USER_ERROR:
            return {
                ...state,
                DELETE_USER_REQUEST: null,
                DELETE_USER_ERROR: action.payload
            }
        case DELETE_USER_SUCCESS:
            const filteredUsers = state.users.filter(user => user.username !== action.payload)
            return {
                ...state,
                DELETE_USER_REQUEST: null,
                DELETE_USER_SUCCESS: true,
                users: filteredUsers
            }
        default:
            return state
    }
}