import { createStore, combineReducers, applyMiddleware } from 'redux';
import users from './reducers/users';
import inventories from './reducers/inventories';
import authentication from './reducers/authentication';
import counts from './reducers/counts';
import userFields from './reducers/user-fields';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

const reducers = combineReducers({
    users,
    inventories,
    authentication,
    counts,
    userFields
});

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
