import {
    FETCH_COUNTS_REQUEST, FETCH_COUNTS_ERROR, FETCH_COUNTS_SUCCESS,
    ADD_COUNT_REQUEST, ADD_COUNT_ERROR, ADD_COUNT_SUCCESS,
    UPDATE_COUNT_REQUEST, UPDATE_COUNT_ERROR, UPDATE_COUNT_SUCCESS,
    DELETE_COUNT_REQUEST, DELETE_COUNT_ERROR, DELETE_COUNT_SUCCESS,
} from '../actions/types'

const defaultState = {
    counts: [],
    metrics: {},
    usersSummary: [],
    locationSummary: [],
    FETCH_COUNTS_REQUEST: null,
    FETCH_COUNTS_ERROR: null,
    FETCH_COUNTS_SUCCESS: null,
    ADD_COUNT_REQUEST: null,
    ADD_COUNT_ERROR: null,
    ADD_COUNT_SUCCESS: null,
    UPDATE_COUNT_REQUEST: null,
    UPDATE_COUNT_ERROR: null,
    UPDATE_COUNT_SUCCESS: null,
    DELETE_COUNT_REQUEST: null,
    DELETE_COUNT_ERROR: null,
    DELETE_COUNT_SUCCESS: null,
};

export default function counts(state = defaultState, action) {
    switch (action.type) {
        case FETCH_COUNTS_REQUEST:
            return {
                ...state,
                FETCH_COUNTS_REQUEST: true,
                FETCH_COUNTS_ERROR: null,
                FETCH_COUNTS_SUCCESS: null
            };
        case FETCH_COUNTS_ERROR:
            return {
                ...state,
                FETCH_COUNTS_REQUEST: null,
                FETCH_COUNTS_ERROR: action.payload
            };
        case FETCH_COUNTS_SUCCESS:
            const counts = action.payload;

            return {
                ...state,
                FETCH_COUNTS_REQUEST: null,
                FETCH_COUNTS_SUCCESS: true,
                counts,
                ...(counts.length !== 0 ?
                    calculateMetricsAndSummaries(counts)
                    : {
                        metrics: defaultState.metrics,
                        usersSummary: defaultState.usersSummary,
                        locationSummary: defaultState.locationSummary
                    })
            };
        case ADD_COUNT_REQUEST:
            return {
                ...state,
                ADD_COUNT_REQUEST: true,
                ADD_COUNT_ERROR: null,
                ADD_COUNT_SUCCESS: null
            }
        case ADD_COUNT_ERROR:

            return {
                ...state,
                ADD_COUNT_REQUEST: null,
                ADD_COUNT_ERROR: action.payload
            };
        case ADD_COUNT_SUCCESS:

            return {
                ...state,
                ADD_COUNT_REQUEST: null,
                ADD_COUNT_SUCCESS: true,
                counts: [...state.counts, action.payload],
                ...calculateMetricsAndSummaries([...state.counts, action.payload])
            };
        case UPDATE_COUNT_REQUEST:
            return {
                ...state,
                UPDATE_COUNT_REQUEST: true,
                UPDATE_COUNT_ERROR: null,
                UPDATE_COUNT_SUCCESS: null
            }
        case UPDATE_COUNT_ERROR:

            return {
                ...state,
                UPDATE_COUNT_REQUEST: null,
                UPDATE_COUNT_ERROR: action.payload
            };
        case UPDATE_COUNT_SUCCESS:

            return {
                ...state,
                UPDATE_COUNT_REQUEST: null,
                UPDATE_COUNT_SUCCESS: true,
                counts: state.counts.map(count => count.uuid !== action.payload.uuid ? count : action.payload),
                ...calculateMetricsAndSummaries([...state.counts, action.payload])
            };
        case DELETE_COUNT_REQUEST:
            return {
                ...state,
                DELETE_COUNT_REQUEST: true,
                DELETE_COUNT_ERROR: null,
                DELETE_COUNT_SUCCESS: null
            }
        case DELETE_COUNT_ERROR:

            return {
                ...state,
                DELETE_COUNT_REQUEST: null,
                DELETE_COUNT_ERROR: action.payload
            };
        case DELETE_COUNT_SUCCESS:
            const filteredCounts = state.counts.filter(count => count.uuid !== action.payload);
            return {
                ...state,
                DELETE_COUNT_REQUEST: null,
                DELETE_COUNT_SUCCESS: true,
                counts: filteredCounts,
                ...(filteredCounts.length !== 0 ?
                    calculateMetricsAndSummaries(filteredCounts)
                    : {
                        metrics: defaultState.metrics,
                        usersSummary: defaultState.usersSummary,
                        locationSummary: defaultState.locationSummary
                    })
            };

        default:
            return state
    }
};

export const calculateMetricsAndSummaries = (counts) => {

    const locationSummary = calculateLocationSummary(counts);
    const usersSummary = calculateUserSummary(counts);

    const distinctSKUs = []
    let totalCountedValue = 0;

    for (const count of counts) {
        distinctSKUs.push(count['Product.sku']);
        totalCountedValue += count['Product.price'] * count.quantity
    }

    const metrics = {
        total_counts: counts.length,
        first_datetime: counts[0].created_at,
        last_datetime: counts[counts.length - 1].created_at,
        distinctSKUs: [...new Set(distinctSKUs)].length,
        totalCountedValue
    }

    return { metrics, locationSummary, usersSummary }
};

const calculateUserSummary = (counts) => {
    const groupedCountsByUser = groupBy(counts, 'user.username');
    let usersSummary = [];
    for (const user in groupedCountsByUser) {
        usersSummary.push({
            username: groupedCountsByUser[user][0]['user.username'],
            total_registers: groupedCountsByUser[user].length,
            first_register_datetime: groupedCountsByUser[user][0].created_at,
            last_register_datetime: groupedCountsByUser[user].slice(-1)[0].created_at
        })
    };
    return usersSummary;
};

const calculateLocationSummary = (counts) => {
    const groupedCountsByLocation = groupBy(counts, 'location');
    let locationSummary = [];
    for (const location in groupedCountsByLocation) {
        locationSummary.push({ location, total_registers: groupedCountsByLocation[location].length })
    };
    return locationSummary;
}


//Helper function to compute metrics.
function groupBy(arr, criteria) {
    const newObj = arr.reduce(function (acc, currentValue) {
        if (!acc[currentValue[criteria]]) {
            acc[currentValue[criteria]] = [];
        }
        acc[currentValue[criteria]].push(currentValue);
        return acc;
    }, {});
    return newObj;
}