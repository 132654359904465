import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: RouteComponent, children, ...rest }) => {

    const { isAuthenticated, emailVerified, isActive, role } = useSelector(state => state.authentication);

    if (isAuthenticated && emailVerified && isActive && role !== 'mobile') {
        return children
    } else {
        return <Navigate to={'/signin'} />
    }

}

export default PrivateRoute;