// ----------------- START OF INVENTORIES ------------------------------

export const FETCH_INVENTORIES_REQUEST = 'FETCH_INVENTORIES_REQUEST';
export const FETCH_INVENTORIES_ERROR = 'FETCH_INVENTORIES_ERROR';
export const FETCH_INVENTORIES_SUCCESS = 'FETCH_INVENTORIES_SUCCESS';

export const ADD_INVENTORY_REQUEST = 'ADD_INVENTORY_REQUEST';
export const ADD_INVENTORY_ERROR = 'ADD_INVENTORY_ERROR';
export const ADD_INVENTORY_SUCCESS = 'ADD_INVENTORY_SUCCESS';

export const UPDATE_INVENTORY_REQUEST = 'UPDATE_INVENTORY_REQUEST';
export const UPDATE_INVENTORY_ERROR = 'UPDATE_INVENTORY_ERROR';
export const UPDATE_INVENTORY_SUCCESS = 'UPDATE_INVENTORY_SUCCESS';

export const DELETE_INVENTORY_REQUEST = 'DELETE_INVENTORY_REQUEST';
export const DELETE_INVENTORY_ERROR = 'DELETE_INVENTORY_ERROR';
export const DELETE_INVENTORY_SUCCESS = 'DELETE_INVENTORY_SUCCESS';

export const SET_CURRENT_INVENTORY = 'SET_CURRENT_INVENTORY'

// ----------------- END OF INVENTORIES --------------------------------

// ----------------- START OF USERS ------------------------------
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';


export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

// ----------------- END OF USERS --------------------------------

// ----------------- START OF COUNTS ------------------------------
export const FETCH_COUNTS_REQUEST = 'FETCH_COUNTS_REQUEST';
export const FETCH_COUNTS_ERROR = 'FETCH_COUNTS_ERROR';
export const FETCH_COUNTS_SUCCESS = 'FETCH_COUNTS_SUCCESS';


export const ADD_COUNT_REQUEST = 'ADD_COUNT_REQUEST';
export const ADD_COUNT_ERROR = 'ADD_COUNT_ERROR';
export const ADD_COUNT_SUCCESS = 'ADD_COUNT_SUCCESS';

export const UPDATE_COUNT_REQUEST = 'UPDATE_COUNT_REQUEST';
export const UPDATE_COUNT_ERROR = 'UPDATE_COUNT_ERROR';
export const UPDATE_COUNT_SUCCESS = 'UPDATE_COUNT_SUCCESS';

export const DELETE_COUNT_REQUEST = 'DELETE_COUNT_REQUEST';
export const DELETE_COUNT_ERROR = 'DELETE_COUNT_ERROR';
export const DELETE_COUNT_SUCCESS = 'DELETE_COUNT_SUCCESS';

// ----------------- END OF COUNTS --------------------------------

// ------------------ START OF AUTHENTICATION --------------------.
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export const EMAIL_VALIDATION_REQUEST = 'EMAIL_VALIDATION_REQUEST';
export const EMAIL_VALIDATION_ERROR = 'EMAIL_VALIDATION_ERROR';
export const EMAIL_VALIDATION_SUCCESS = 'EMAIL_VALIDATION_SUCCESS';

export const LOGOUT_USER = 'LOGOUT USER';

export const RESEND_EMAIL_VALIDATION_REQUEST = 'RESEND_EMAIL_VALIDATION_REQUEST'
export const RESEND_EMAIL_VALIDATION_ERROR = 'RESEND_EMAIL_VALIDATION_ERROR'
export const RESEND_EMAIL_VALIDATION_SUCCESS = 'RESEND_EMAIL_VALIDATION_SUCCESS'


export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';

export const FETCH_USER_FIELDS = 'FETCH_USER_FIELDS';
export const UPDATE_USER_FIELD = 'UPDATE_USER_FIELD';
export const CREATE_USER_FIELD = 'CREATE_USER_FIELD';

// ------------------ END OF AUTHENTICATION --------------------.