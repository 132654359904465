import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { MuiThemeProvider, CssBaseline, Box, CircularProgress } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import PrivateRoute from "./shared/components/PrivateRoute"
import theme from "./theme";
import { Provider } from "react-redux"
import { store } from "./redux/store"
//import WebSocketProvider from './contexts/Context'
// Internationalization.
import { I18nextProvider } from 'react-i18next';
import i18next from "i18next";

//NOTISTACK
import { SnackbarProvider } from 'notistack';

// Translation files.
import './App.css';
import home_es from "./translations/es/loggedOut/home.json"
import home_en from "./translations/en/loggedOut/home.json"
import register_es from "./translations/es/loggedOut/register.json"
import register_en from "./translations/en/loggedOut/register.json"
import pricing_es from "./translations/es/pricing.json"
import pricing_en from "./translations/en/pricing.json"
import login_en from "./translations/en/loggedOut/login.json"
import login_es from "./translations/es/loggedOut/login.json"

import loggedNavigation_es from "./translations/es/loggedIn/navigation.json"
import loggedNavigation_en from "./translations/en/loggedIn/navigation.json"

import general_es from "./translations/es/loggedIn/general.json"
import general_en from "./translations/en/loggedIn/general.json"

import LoggedOutComponent from './logged_out/components';
const LoggedInComponent = React.lazy(() => import("./logged_in/components"));


i18next.init({
  fallbackLng: "es",
  lng: localStorage.getItem('language') ? localStorage.getItem('language') : 'es',
  interpolation: { escapeValue: false },
  resources: {
    es: {
      home: home_es,
      loggedNavigation: loggedNavigation_es,
      register: register_es,
      pricing: pricing_es,
      login: login_es,
      general: general_es
    },
    en: {
      home: home_en,
      loggedNavigation: loggedNavigation_en,
      register: register_en,
      pricing: pricing_en,
      login: login_en,
      general: general_en
    },
  }
});


function App() {

  return (
    <Suspense fallback={<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' flexGrow={1} style={{ marginBottom: '150px', minHeight: '100vh' }} >
      <CircularProgress size={50} style={{ color: 'black' }} />
    </Box>}>
      <Provider store={store}>
        <SnackbarProvider autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
          TransitionComponent={Zoom}>
          <MuiThemeProvider theme={theme}>
            <I18nextProvider i18n={i18next}>
              <CssBaseline />
              <Routes>
                <Route index path='/*' element={<LoggedOutComponent />} />
                <Route path='/app/*'
                  element={
                    <PrivateRoute >
                      <LoggedInComponent />
                    </PrivateRoute>}
                />
              </Routes>
            </I18nextProvider>
          </MuiThemeProvider>
        </SnackbarProvider>
      </Provider>
    </Suspense>
  )
}

export default App;
