import {
    LOGIN_USER_REQUEST, LOGIN_USER_ERROR, LOGIN_USER_SUCCESS,
    REGISTER_USER_REQUEST, REGISTER_USER_ERROR, REGISTER_USER_SUCCESS,
    EMAIL_VALIDATION_REQUEST, EMAIL_VALIDATION_ERROR, EMAIL_VALIDATION_SUCCESS,
    RESEND_EMAIL_VALIDATION_REQUEST, RESEND_EMAIL_VALIDATION_ERROR, RESEND_EMAIL_VALIDATION_SUCCESS,
    REFRESH_TOKEN_ERROR, REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS,
    LOGOUT_USER
} from '../actions/types'

// User authentication data is persisted in localStorage.
let authenticationData = JSON.parse(localStorage.getItem('user'));

authenticationData = {
    emailVerified: false,
    isActive: false,
    isAuthenticated: false,
    role: null,
    ...authenticationData,
}

const authenticationDefaultState = {
    ...authenticationData,
    LOGIN_USER_REQUEST: null,
    LOGIN_USER_ERROR: null,
    LOGIN_USER_SUCCESS: null,
    REGISTER_USER_REQUEST: null,
    REGISTER_USER_ERROR: null,
    REGISTER_USER_SUCCESS: null,
    EMAIL_VALIDATION_REQUEST: null,
    EMAIL_VALIDATION_ERROR: null,
    EMAIL_VALIDATION_SUCCESS: null,
    RESEND_EMAIL_VALIDATION_REQUEST: null,
    RESEND_EMAIL_VALIDATION_ERROR: null,
    RESEND_EMAIL_VALIDATION_SUCCESS: null,
    REFRESH_TOKEN_REQUEST: null,
    REFRESH_TOKEN_ERROR: null,
    REFRESH_TOKEN_SUCCESS: null
}

export default function authentication(state = authenticationDefaultState, action) {
    switch (action.type) {
        case LOGIN_USER_REQUEST:
            return {
                ...state,
                LOGIN_USER_REQUEST: true,
                LOGIN_USER_ERROR: null,
                LOGIN_USER_SUCCESS: null
            };
        case LOGIN_USER_ERROR:
            return {
                ...state,
                LOGIN_USER_REQUEST: null,
                LOGIN_USER_ERROR: action.payload
            };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                LOGIN_USER_REQUEST: null,
                LOGIN_USER_SUCCESS: true
            };

        case REGISTER_USER_REQUEST:
            return {
                ...state,
                REGISTER_USER_REQUEST: true,
                REGISTER_USER_ERROR: null,
                REGISTER_USER_SUCCESS: null
            };
        case REGISTER_USER_ERROR:
            return {
                ...state,
                REGISTER_USER_REQUEST: null,
                REGISTER_USER_ERROR: action.payload
            };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                REGISTER_USER_REQUEST: null,
                REGISTER_USER_SUCCESS: true
            };
        case LOGOUT_USER: {
            return {
                ...state,
                isAuthenticated: false
            }
        }
        case EMAIL_VALIDATION_REQUEST:
            return {
                ...state,
                EMAIL_VALIDATION_REQUEST: true,
                EMAIL_VALIDATION_ERROR: null,
                EMAIL_VALIDATION_SUCCESS: null
            };
        case EMAIL_VALIDATION_ERROR:
            return {
                ...state,
                EMAIL_VALIDATION_REQUEST: null,
                EMAIL_VALIDATION_ERROR: action.payload
            };
        case EMAIL_VALIDATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                EMAIL_VALIDATION_REQUEST: null,
                EMAIL_VALIDATION_SUCCESS: true
            };
        case RESEND_EMAIL_VALIDATION_REQUEST:
            return {
                ...state,
                RESEND_EMAIL_VALIDATION_REQUEST: true,
                RESEND_EMAIL_VALIDATION_ERROR: null,
                RESEND_EMAIL_VALIDATION_SUCCESS: null
            };
        case RESEND_EMAIL_VALIDATION_ERROR:
            return {
                ...state,
                RESEND_EMAIL_VALIDATION_REQUEST: null,
                RESEND_EMAIL_VALIDATION_ERROR: action.payload
            };
        case RESEND_EMAIL_VALIDATION_SUCCESS:
            return {
                ...state,
                RESEND_EMAIL_VALIDATION_REQUEST: null,
                RESEND_EMAIL_VALIDATION_SUCCESS: true
            };
        case REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                REFRESH_TOKEN_REQUEST: true,
                REFRESH_TOKEN_ERROR: null,
                REFRESH_TOKEN_SUCCESS: null
            };
        case REFRESH_TOKEN_ERROR:
            return {
                ...state,
                REFRESH_TOKEN_REQUEST: null,
                REFRESH_TOKEN_ERROR: action.payload
            };
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                REFRESH_TOKEN_REQUEST: null,
                REFRESH_TOKEN_SUCCESS: true,
                ...action.payload,
            };

        default:
            return state
    }
}