import React from 'react';
import { Route, Routes } from 'react-router-dom';

const SignUp = React.lazy(() => import("./register_login/Register/SignUp"));
const SignIn = React.lazy(() => import("./register_login/Login/SignIn"));
const ResetPassword = React.lazy(() => import("./reset-password/ResetPassword"));

const LoggedOutComponent = () => {

    return (
        <Routes>
            <Route index path='/' element={<SignIn />} />
            <Route path='signin' element={<SignIn />} />
            <Route path='signup' element={<SignUp />} />
            <Route path='reset-password' element={<ResetPassword />} />
        </Routes>
    )
};

export default LoggedOutComponent;