import { FETCH_USER_FIELDS, CREATE_USER_FIELD, UPDATE_USER_FIELD } from '../actions/types';

const defaultState = { userFields: [] }

export default function inventories(state = defaultState, action) {
    switch (action.type) {
        case FETCH_USER_FIELDS:
            return { ...state, userFields: action.payload };
        case UPDATE_USER_FIELD:
            const updatedUserFields = state.userFields.map(userField => {
                if (userField.uuid === action.payload.uuid) {
                    return action.payload;
                } else {
                    return userField;
                }
            })
            return { ...state, userFields: updatedUserFields };
        case CREATE_USER_FIELD:
            return { ...state, userFields: [action.payload, ...state.userFields] };
        default:
            return state
    }
}