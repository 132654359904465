import {
    FETCH_INVENTORIES_REQUEST, FETCH_INVENTORIES_ERROR, FETCH_INVENTORIES_SUCCESS,
    ADD_INVENTORY_REQUEST, ADD_INVENTORY_ERROR, ADD_INVENTORY_SUCCESS,
    UPDATE_INVENTORY_REQUEST, UPDATE_INVENTORY_ERROR, UPDATE_INVENTORY_SUCCESS,
    SET_CURRENT_INVENTORY,
    DELETE_INVENTORY_REQUEST,
    DELETE_INVENTORY_ERROR,
    DELETE_INVENTORY_SUCCESS
} from '../actions/types';

let currentInventory = localStorage.getItem('currentInventory');
currentInventory = currentInventory && JSON.parse(currentInventory);

const defaultState = {
    inventories: [],
    currentInventory: currentInventory ? currentInventory : null,
    FETCH_INVENTORIES_REQUEST: null,
    FETCH_INVENTORIES_ERROR: null,
    FETCH_INVENTORIES_SUCCESS: null,
    ADD_INVENTORY_REQUEST: null,
    ADD_INVENTORY_ERROR: null,
    ADD_INVENTORY_SUCCESS: null,
    UPDATE_INVENTORY_REQUEST: null,
    UPDATE_INVENTORY_ERROR: null,
    UPDATE_INVENTORY_SUCCESS: null,
    DELETE_INVENTORY_REQUEST: null,
    DELETE_INVENTORY_ERROR: null,
    DELETE_INVENTORY_SUCCESS: null
}

export default function inventories(state = defaultState, action) {
    switch (action.type) {
        case FETCH_INVENTORIES_REQUEST:
            return {
                ...state,
                FETCH_INVENTORIES_REQUEST: true,
                FETCH_INVENTORIES_ERROR: null,
                FETCH_INVENTORIES_SUCCESS: null
            };
        case FETCH_INVENTORIES_ERROR:
            return {
                ...state,
                FETCH_INVENTORIES_REQUEST: null,
                FETCH_INVENTORIES_ERROR: action.payload
            };
        case FETCH_INVENTORIES_SUCCESS:
            return {
                ...state,
                inventories: action.payload,
                FETCH_INVENTORIES_REQUEST: null,
                FETCH_INVENTORIES_SUCCESS: true
            };
        case ADD_INVENTORY_REQUEST:
            return {
                ...state,
                ADD_INVENTORY_REQUEST: true,
                ADD_INVENTORY_ERROR: null,
                ADD_INVENTORY_SUCCESS: null
            };
        case ADD_INVENTORY_ERROR:
            return {
                ...state,
                ADD_INVENTORY_REQUEST: null,
                ADD_INVENTORY_ERROR: action.payload
            };
        case ADD_INVENTORY_SUCCESS:
            return {
                ...state,
                ADD_INVENTORY_REQUEST: null,
                ADD_INVENTORY_SUCCESS: true,
                inventories: [...state.inventories, action.payload]
            };
        case UPDATE_INVENTORY_REQUEST:
            return {
                ...state,
                UPDATE_INVENTORY_REQUEST: true,
                UPDATE_INVENTORY_ERROR: null,
                UPDATE_INVENTORY_SUCCESS: null
            };
        case UPDATE_INVENTORY_ERROR:
            return {
                ...state,
                UPDATE_INVENTORY_REQUEST: null,
                UPDATE_INVENTORY_ERROR: action.payload
            };
        case UPDATE_INVENTORY_SUCCESS:
            const updatedInventories = state.inventories.map(inventory => {
                if (inventory.uuid !== action.payload.uuid) return inventory
                return action.payload
            })
            return {
                ...state,
                UPDATE_INVENTORY_REQUEST: null,
                UPDATE_INVENTORY_SUCCESS: true,
                inventories: updatedInventories
            };
        case DELETE_INVENTORY_REQUEST:
            return {
                ...state,
                DELETE_INVENTORY_REQUEST: true,
                DELETE_INVENTORY_ERROR: null,
                DELETE_INVENTORY_SUCCESS: null
            };
        case DELETE_INVENTORY_ERROR:
            return {
                ...state,
                DELETE_INVENTORY_REQUEST: null,
                DELETE_INVENTORY_ERROR: action.payload
            };
        case DELETE_INVENTORY_SUCCESS:
            const filteredInventories = state.inventories.filter(inventory => inventory.uuid !== action.payload);

            return {
                ...state,
                DELETE_INVENTORY_REQUEST: null,
                DELETE_INVENTORY_SUCCESS: true,
                inventories: filteredInventories
            };
        case SET_CURRENT_INVENTORY:
            return {
                ...state,
                currentInventory: action.payload
            }
        default:
            return state
    }
}