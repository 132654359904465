import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#233044',
    },
    secondary: {
      main: '#39A1FF',
    },
    error: {
      main: '#D95B50'
    }
  },
  typography: {
    fontFamily: "'Inter', 'Public Sans', 'sans-serif', 'Roboto', 'Helvetica', 'Arial'",
    fontWeight: 400
  },
  overrides: {
    MuiTableSortLabel: {
      root: {
        color: 'black',
        fontWeight: 700,
        fontSize: 15,
        '&:hover': {
          color: '#233044'
        },
      },
      active: {
        color: '#233044'
      },
    },
  }
});

export default theme;